import React from "react";
import Header from "./components/Header";
import Description from "./components/Description";
import Carousel from "./components/Carousel";
import Product from "./components/Product";
import VRBrands from "./components/VRBrands";
import Footer from "./components/Footer";
import Imprint from "./components/Imprint";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="page-container">
        <Switch>
          <Route path="/" exact>
            <Header />
            <Description />
            <Product />
            <Carousel />
            <VRBrands />
            <Footer />
          </Route>
          <Route path="/imprint">
            <Header />
            <Imprint />
            <Footer />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
