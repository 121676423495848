import React from "react";
import inflightLogo from "../assets/inflight_logo.png";
import frontrowLogo from "../assets/frontrow_logo.png";
import GetInTouchButton from "./GetInTouchButton";

const Header = () => {
  return (
    <header className="vres-header">
      <a href="https://inflight-vr.com" target="_blank" rel="noopener noreferrer">
        <img src={inflightLogo} className="inflight-logo" alt="InflightVR" />
      </a>
      <a href="https://www.thefrontrow.io" target="_blank" rel="noopener noreferrer">
        <img src={frontrowLogo} className="frontrow-logo" alt="frontrow" />
      </a>
      <GetInTouchButton />
    </header>
  );
};

export default Header;
