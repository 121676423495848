import React, { useState } from "react";
import { Carousel as PrimeCarousel } from "primereact/carousel";
import ergoLogo from "../assets/ergo_logo.png";
import tiSentoLogo from "../assets/tisento_logo.png";
import legoLogo from "../assets/lego_logo.png";
import miniLogo from "../assets/mini_logo.png";

type ILogo = {
  logo: string;
  className?: string;
  name: string;
};
const Logo = (props: ILogo) => {
  return <img src={props.logo} className={"brand " + props.className || ""} alt={props.name} />;
};

const brandList = [
  {
    logo: ergoLogo,
    name: "ERGO",
  },
  {
    logo: tiSentoLogo,
    name: "Ti Sento",
  },
  {
    logo: legoLogo,
    name: "LEGO",
  },
  {
    logo: miniLogo,
    name: "mini",
  },
];
const Carousel = () => {
  let [brands] = useState(brandList);
  const responsiveOptions = [
    {
      breakpoint: "1110px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "850px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "415px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  return (
    <div className="carousel-section">
      <div className="carousel-header">Brands we have already worked with:</div>
      <PrimeCarousel
        className="custom-carousel"
        value={brands}
        itemTemplate={(row: ILogo) => <Logo logo={row.logo} name={row.name} />}
        numVisible={4}
        numScroll={1}
        responsiveOptions={responsiveOptions}
        // autoplayInterval={3000}
        circular={true}
      />
    </div>
  );
};

export default Carousel;
