import React from "react";

const GetInTouchButton = () => {
  const onClick = () => {
    window.location.href = `mailto:contact@thevres.com`;
  };

  return (
    <button onClick={onClick} className="get-in-touch-button">
      GET IN TOUCH
    </button>
  );
};

export default GetInTouchButton;
