import React, { useState } from "react";
import vresLogo from "../assets/vres_logo.png";
import jsonp from "jsonp";
import queryString from "query-string";
import { Link } from "react-router-dom";

const Footer = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [error, setError] = useState<string | JSX.Element | null>(null);
  const [success, setSuccess] = useState(false);

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const subscribe = () => {
    if (!email) {
      setError("You must provide an email address");
    } else if (!new RegExp(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/).test(email)) {
      setError("Email format is invalid. Try an email with the format 'name@domain.com'");
    } else {
      setError(null);
      const formData = {
        EMAIL: email,
      };
      jsonp(
        `https://inflight-vr.us10.list-manage.com/subscribe/post-json?u=af1572792aada1a07a5b2a675&amp;id=b007be9ddc&${queryString.stringify(
          formData
        )}`,
        { param: "c" },
        (err, data) => {
          console.log("err:", err);
          console.log("data:", data);
          if (data) {
            console.log(data);
            if (data.result !== "error") {
              setSuccess(true);
              setEmail(null);
            } else {
              setError(<div dangerouslySetInnerHTML={{ __html: data.msg }} />);
            }
          } else {
            setError("Sorry! The subscribe button is not working right now, please try later");
          }
        }
      );
    }
  };
  return (
    <div className="footer-section" id="mc_embed_signup">
      <div className="logo-and-links">
        <img src={vresLogo} className="vres-logo" alt="InflightVR" onClick={() => window.scrollTo(0, 0)} />
        <Link to="/imprint" onClick={() => window.scrollTo(0, 0)}>
          <span className="footer-links">Imprint & Data Policy</span>
        </Link>
      </div>
      <div className="subcribe-container">
        <h3>Want to stay in the loop?</h3>
        <div className="subscribe-input">
          <input
            type="email"
            name="EMAIL"
            id="mce-EMAIL"
            className="email"
            placeholder="your@email.here"
            onChange={onChangeEmail}
            value={email || ""}
            autoCapitalize="off"
            autoCorrect="off"
          />
          <div style={{ position: "absolute", left: -5000 }} aria-hidden="true">
            <input
              type="text"
              name="b_af1572792aada1a07a5b2a675_8a7d18e741"
              tabIndex={-1}
              value=""
              onChange={() => {}}
            />
          </div>
          <button type="submit" onClick={subscribe} value="Subscribe" name="Subscribe" id="mc-embedded-subscribe">
            <span className="subscribe-text">Subscribe</span>
          </button>
          <div className="email-error">{error}</div>
          {success && (
            <div className="email-success">
              <span>✓ You've subscribed successfully to our mailing list.</span>
            </div>
          )}
        </div>
        <iframe title="hiddenFrame" name="hiddenFrame" src="about:blank" style={{ display: "none" }}></iframe>
      </div>
    </div>
  );
};

export default Footer;
