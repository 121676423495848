import React from "react";
import vresLogo from "../assets/vres_logo.png";
import e2eComicBubble from "../assets/e2e-comic-bubble.png";
import GetInTouchButton from "./GetInTouchButton";

const Description = () => {
  return (
    <div className="description-section">
      <div className="columns">
        <div className="left-column">
          <img src={vresLogo} className="vres-logo" alt="InflightVR" />
          <h1>The Virtual Reality Expert Services Provider for Enterprises</h1>
          <p>
            We are the leading end-to-end provider for a customizable B2B2C VR platform with a proven track record
            including{" "}
            <a href="https://inflight-vr.com" target="_blank" rel="noopener noreferrer">
              Inflight VR
            </a>{" "}
            and{" "}
            <a href="https://www.thefrontrow.io" target="_blank" rel="noopener noreferrer">
              frontrow
            </a>
            .
          </p>

          <p>
            Plan and implement any VR project with us and benefit from our expertise and execution speed. No prior
            experience with VR needed. No capex through own headsets required.
          </p>

          <p>We support you for making virtual reality a business reality for you.</p>
          <GetInTouchButton />
        </div>
        <img src={e2eComicBubble} className="e2e-comic-bubble" alt="e2e solution" />
        <div className="right-column">
          <div className="inflight-collage" />
        </div>
      </div>
      <div className="greyish-banner">
        <ul>
          <li>Present your brand(s) & product(s) in VR</li>
          <li>Expand your reach & maximize your budget</li>
          <li>Reach your network (in times of restricted travel)</li>
          <li>Create an emotional experience and differentiate</li>
        </ul>
      </div>
    </div>
  );
};

export default Description;
