import React from "react";
import inflightLogoWhite from "../assets/inflight_logo_white.png";
import frontrowLogoWhite from "../assets/frontrow_logo_white.png";

const VRBrands = () => {
  return (
    <div className="vr-brands-section">
      <h1>You can trust in VRES</h1>
      <h2>Proven use cases with industry-leading enterprises:</h2>
      <div className="cards-container">
        <a className="card inflight-vr-brand" href="https://inflight-vr.com" target="_blank" rel="noopener noreferrer">
          <img src={inflightLogoWhite} className="inflight-logo-white" alt="InflightVR" />
          <p>The leading VR entertainment solution for the travel industry.</p>
        </a>
        <a className="card frontrow-brand" href="https://www.thefrontrow.io" target="_blank" rel="noopener noreferrer">
          <img src={frontrowLogoWhite} className="frontrow-logo-white" alt="frontrow" />
          <p>VR experience hub for cultural institutions. The Netflix of VR in culture.</p>
        </a>
      </div>
    </div>
  );
};

export default VRBrands;
