import React from "react";
import headsetImage from "../assets/headset.png";
import iPlaform from "../assets/icon_platform.jpg";
import iContent from "../assets/icon_content.jpg";
import iManagement from "../assets/icon_management.jpg";
import iHardware from "../assets/icon_hardware.jpg";
import iDeployment from "../assets/icon_deployment.jpg";
import iAnalytics from "../assets/icon_analytics.jpg";
import noOwnHeadsetComicBubble from "../assets/no-headset-comic-bubble.png";

type Card = {
  img: string;
  className?: string;
  imageClassName?: string;
  name: string;
  title: string;
  description: string;
  orientation?: string;
};
const Card = (props: Card) => {
  return (
    <div data-orientation={props.orientation || "right"} className={"card-container " + props.className || ""}>
      <img src={props.img} className={"card " + props.imageClassName || ""} alt={props.name} />
      <h2>{props.title}</h2>
      <p>{props.description}</p>
    </div>
  );
};

const Product = () => {
  return (
    <div className="product-section">
      <h1>What can VRES offer?</h1>
      <div className="columns">
        <Card
          img={iPlaform}
          className="product-platform"
          imageClassName="img-product-platform"
          name="360 view"
          title="VR Software Platform"
          description="A customizable platform UI & motion-sickness free UX."
          orientation="right"
        />
        <div className="headset-and-bubble">
          <img src={noOwnHeadsetComicBubble} className="no-own-headset-comic-bubble" alt="no own headset" />
          <img src={headsetImage} className={"headset"} alt="VR Headset" />
        </div>
        <Card
          img={iHardware}
          className="product-hardware"
          imageClassName="img-product-hardware"
          name="headset"
          title="Hardware Selection"
          description="The right standalone VR hardware for every use case – provided by us."
          orientation="left"
        />
        <Card
          img={iContent}
          className="product-content"
          imageClassName="img-product-content"
          name="play button"
          title="VR Content"
          description="Support in the content creation process & access to our vast content library."
          orientation="right"
        />
        <Card
          img={iDeployment}
          className="product-deployment"
          imageClassName="img-product-deployment"
          name="toolbox"
          title="Deployment Tools"
          description="Deployment & integration of hundreds of VR headsets within no time."
          orientation="left"
        />
        <Card
          img={iManagement}
          className="product-management"
          imageClassName="img-product-management"
          name="wifi box"
          title="Content Management"
          description="Web-based portal to manage your content and remotely update the headsets."
          orientation="right"
        />
        <Card
          img={iAnalytics}
          className="product-analytics"
          imageClassName="img-product-analytics"
          name="page"
          title="Analytics"
          description="Real-time analytics engine for gathering user insights."
          orientation="left"
        />
      </div>
    </div>
  );
};

export default Product;
